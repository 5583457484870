<template>
  <nav class="navbar">
    <div class="container">
      <a href="#" class="navbar-brand">马天乐的 AI 网站</a>
      <ul class="navbar-nav">
        <li><a href="#home">首页</a></li>
        <li><a href="#ai-portfolio">AI 作品</a></li>
        <li><a href="#ai-chat">AI 对话</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
.navbar {
  background-color: #3498db; /* 浅蓝色背景 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar-brand {
  color: #ffffff; /* 白色文字 */
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-nav li {
  margin-left: 1.5rem;
  display: inline-block;
}

.navbar-nav a {
  color: #ffffff; /* 白色文字 */
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
}

.navbar-nav a:hover {
  color: #2c3e50; /* 深色悬停效果 */
}
</style>
