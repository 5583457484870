<template>
  <div id="app">
    <!-- 导航栏 -->
    <nav>
      <NavBar />
    </nav>

    <!-- 主要内容区域 -->
    <main>
      <!-- 头部区域 -->
      <header id="home" class="hero" :style="{ backgroundImage: `url(${bannerImage})` }">
        <div class="hero-content">
          <h1>马天乐的 AI 网站</h1>
          <p class="subtitle">探索 AIGC 的无限可能性</p>
        </div>
      </header>

      <!-- AI 作品展示 -->
      <section id="ai-portfolio">
        <div class="container">
          <h2 class="section-title">AI 作品展示</h2>
          <div class="portfolio-grid">
            <div class="portfolio-item" v-for="item in portfolioItems" :key="item.id">
              <div class="image-container">
                <img :src="item.image" :alt="item.title" v-if="!item.compareImages">
                <div v-if="item.compareImages" class="image-comparison">
                  <img :src="item.compareImages.old" alt="Old Image" class="image-old">
                  <img :src="item.compareImages.new" alt="New Image" class="image-new">
                  <input type="range" min="0" max="100" value="50" class="slider" @input="updateComparison($event, item.id)">
                  <div class="slider-button">
                    <span class="slider-arrow left">&#8592;</span>
                    <span class="slider-arrow right">&#8594;</span>
                  </div>
                  <div class="slider-hint">滑动查看对比</div>
                </div>
                <div v-if="item.video" class="play-icon" @click="openVideo(item.video)"></div>
              </div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
              <button v-if="item.pptLink" @click="downloadPPT(item.pptLink)" class="download-btn">下载PPT</button>
            </div>
          </div>
        </div>
      </section>

      <!-- AI 对话栏 -->
      <section id="ai-chat">
        <div class="container">
          <h2 class="section-title">AI 对话</h2>
          <div class="chat-container">
            <div class="chat-messages">
              <div v-for="(message, index) in chatMessages" :key="index" :class="['message', message.role]">
                {{ message.content }}
              </div>
              <div v-if="isLoading" class="message assistant loading">
                <div class="loading-text">AI思考中</div>
                <div class="loading-indicator">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
            </div>
            <div class="chat-input">
              <input 
                type="text" 
                v-model="userMessage" 
                placeholder="输入您的问题..." 
                @keyup.enter="sendMessage"
                class="message-input"
                :disabled="isLoading"
              >
              <button @click="sendMessage" class="send-btn" :disabled="isLoading">
                提问
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- 页脚 -->
    <footer>
      <div class="container">
        <p>&copy; 马天乐的 AI 网站. 保留所有权利。</p>
      </div>
    </footer>

    <!-- 视频播放弹框 -->
    <div v-if="showVideoModal" class="video-modal" @click="closeVideo">
      <div class="video-container" @click.stop>
        <video ref="videoPlayer" controls playsinline @canplay="videoCanPlay" @error="videoError">
          <source :src="currentVideo" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div v-if="videoLoading" class="video-loading">加载中...</div>
        <div v-if="videoError" class="video-error">视频加载失败</div>
        <button class="close-btn" @click="closeVideo">&times;</button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import KJUR from 'jsrsasign'
import catGirlImage from '@/assets/cat-girl.png'
import cityImage from '@/assets/city.png'
import cityVideo from '@/assets/city.mp4'
import teaImage from '@/assets/tea.jpeg'
import photoOld from '@/assets/photo-old.png'
import photoNew from '@/assets/photo-new.png'
import waterImage from '@/assets/water.jpg'
import waterVideo from '@/assets/water.mp4'
import cokeImage from '@/assets/coke.jpg'
import cokeBanner from '@/assets/coke-banner.png'
import bannerImage from '@/assets/banner.jpeg'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      userMessage: '',
      chatMessages: [],
      portfolioItems: [
        { id: 1, title: 'AI 创意照片', description: '使用 AI 绘图工具 stable Diffusion 生成创意图片，一切皆有可能，商用价值可用于婚纱照、小孩写真等等。', image: catGirlImage },
        { id: 2, title: 'AI Logo 瞬息全宇宙创意视频', description: '使用 stable Diffusion 的插件 deforum 去制作创意视频, AI 视频可以根据不同的需求选择可灵或者即梦等。', image: cityImage, video: cityVideo },
        { id: 3, title: 'AI PPT “无糖茶”全流程智能生成', description: '利用 AI 软件“后裔”进行数据分析采集,再用合适的提示词让 ChatGpt / Kimi 生成 PPT 大纲,智能生成 PPT 。', image: teaImage, pptLink: '/tea.pptx' },
        { id: 4, title: 'AI 老照片修复', description: '用 AI 为主 PS 为辅的方式，高清放大修复老照片。商用价值可以配合 AI 视频，进行一系列的老照片变现。', compareImages: { old: photoOld, new: photoNew } },
        { id: 5, title: 'AI 数字人商用带货视频', description: '用 AI 数字人带货工具“即创”去配合 ChatGpt / Kimi 生成的脚本，加上在小程序“寻鱼”的免费素材，智能生成口型对准的带货视频。', image: waterImage, video: waterVideo },
        { id: 6, title: 'AI 海报设计', description: '只需一张产品图就能用 AI 绘图工具进行无限次数无限风格的海报设计，重复性多的工作还可以考虑使用可多次复用的 comfy ui 工作流快速完成。', compareImages: { old: cokeImage, new: cokeBanner } },
      ],
      apiKey: '7574cd802017a8590cc9a410d1e94dbe.Y74xt1IdIzjdT7Ho',
      isLoading: false,
      showVideoModal: false,
      currentVideo: null,
      bannerImage: bannerImage,
      videoLoading: true,
      videoError: false,
    }
  },
  methods: {
    generateToken() {
      try {
        console.log('API Key:', this.apiKey);
        const [id, secret] = this.apiKey.split('.')
        if (!id || !secret) {
          throw new Error('Invalid API key format');
        }
        console.log('API Key ID:', id);
        
        const header = {
          alg: 'HS256',
          sign_type: 'SIGN'
        };
        
        const payload = {
          api_key: id,
          exp: Math.floor(Date.now() / 1000) + 3600,
          timestamp: Math.floor(Date.now() / 1000),
        };
        
        console.log('Payload:', payload);
        
        const token = KJUR.jws.JWS.sign(null, header, payload, secret);
        console.log('Generated Token:', token);
        return token;
      } catch (e) {
        console.error('Error generating token:', e.message);
        return null;
      }
    },
    sendMessage() {
      if (!this.userMessage.trim() || this.isLoading) return

      const userMessage = { role: 'user', content: this.userMessage }
      this.chatMessages.push(userMessage)
      this.isLoading = true

      const token = this.generateToken()
      if (!token) {
        this.chatMessages.push({ role: 'assistant', content: '抱歉，生成认证令牌时出错。请检查您的API密钥。' })
        this.isLoading = false
        return
      }

      console.log('Sending request with token:', token);

      // 使用环境变量或默认值设置 API 基础 URL
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL || '';
      const apiEndpoint = `${apiBaseUrl}/api/paas/v4/chat/completions`;

      console.log('API Endpoint:', apiEndpoint);

      this.$axios.post(
        apiEndpoint,
        {
          model: 'glm-4',
          messages: [userMessage]
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log('API Response:', response.data);
        const aiMessage = { role: 'assistant', content: response.data.choices[0].message.content }
        this.chatMessages.push(aiMessage)
      }).catch(error => {
        console.error('Error calling AI API:', error);
        let errorMessage = '抱歉，发生了一个错误。请稍后再试。'
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
          errorMessage = `错误 ${error.response.status}: ${error.response.data.error || '未知错误'}`
        } else if (error.request) {
          console.error('No response received:', error.request);
          errorMessage = '未收到服务器响应，请检查您的网络连接。'
        } else {
          console.error('Error setting up request:', error.message);
        }
        this.chatMessages.push({ role: 'assistant', content: errorMessage })
      }).finally(() => {
        this.isLoading = false
        this.userMessage = ''
      })
    },
    openVideo(video) {
      this.currentVideo = video;
      this.showVideoModal = true;
      this.videoLoading = true;
      this.videoError = false;
      this.$nextTick(() => {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.load();
          const playPromise = videoPlayer.play();
          if (playPromise !== undefined) {
            playPromise.then(_ => {
              // 自动播放开始
            }).catch(error => {
              console.log('自动播放被阻止，需要用户交互才能播放');
            });
          }
        }
      });
    },
    closeVideo() {
      this.showVideoModal = false;
      this.currentVideo = null;
      this.videoLoading = true;
      this.videoError = false;
    },
    downloadPPT(link) {
      fetch(link)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'tea.pptx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error('Download failed:', error);
          alert('下载失败，请稍后再试。');
        });
    },
    updateComparison(event, id) {
      const slider = event.target;
      const container = slider.closest('.image-comparison');
      const newImage = container.querySelector('.image-new');
      newImage.style.clipPath = `inset(0 ${100 - slider.value}% 0 0)`;
    },
    videoCanPlay() {
      this.videoLoading = false;
    },
    videoError() {
      this.videoLoading = false;
      this.videoError = true;
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --text-color: #333;
  --bg-color: #f4f4f4;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero {
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.5rem;
}

.section-title {
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0;
  color: var(--secondary-color);
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.portfolio-item {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 创建一个正方形的容器 */
  overflow: hidden;
}

.portfolio-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-item h3 {
  padding: 15px 15px 5px;
  margin: 0;
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.portfolio-item p {
  padding: 0 15px 15px;
  margin: 0;
  font-size: 0.9rem;
  color: #666;
  flex-grow: 1; /* 让描述文字占据剩余空间 */
}

.chat-container {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.chat-messages {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 70%;
}

.user {
  background-color: #e1f5fe;
  align-self: flex-end;
  margin-left: auto;
}

.assistant {
  background-color: #f1f8e9;
  align-self: flex-start;
}

.send-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
}

.send-btn:hover {
  background-color: #2980b9;
}

/* 移除之前的图标相关样式 */
.send-btn i {
  display: none;
}

footer {
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 40px;
}

html {
  scroll-behavior: smooth;
}

/* 添加以下样式 */
main {
  padding-top: 70px; /* 调整这个值以匹配导航栏的高度 */
}

.hero {
  margin-top: -70px; /* 抵消 main 的 padding-top */
  padding-top: 70px; /* 确保内容不被导航栏遮挡 */
}

/* 保持其他样式不变 */

.chat-input {
  display: flex;
  margin-top: 15px;
}

.message-input {
  flex-grow: 1;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.message-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.send-btn:disabled,
.message-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 其他样式保持不变 */

.loading-text {
  font-weight: bold;
  margin-bottom: 8px;
  color: var(--primary-color);
}

.loading-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
}

.loading-indicator .dot {
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin: 0 4px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-indicator .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-indicator .dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

.message.loading {
  background-color: #f1f8e9;
  align-self: flex-start;
  padding: 12px;
}

/* 其他样式保持不变 */

/* 响应式设计 */
@media (max-width: 1024px) {
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

.image-container {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-icon::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid white;
  margin-left: 5px; /* 稍微向右偏移,使三角形居中 */
}

.play-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-container {
  position: relative;
  width: 90%;
  max-width: 1000px;
  aspect-ratio: 16 / 9;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.download-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  margin: 10px 15px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: bold;
}

.download-btn:hover {
  background-color: #2980b9;
}

.image-comparison {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-comparison img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-new {
  clip-path: inset(0 50% 0 0);
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: ew-resize;
  z-index: 10;
}

.slider-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.slider-arrow {
  font-size: 20px;
  color: #333;
}

.slider-arrow.left {
  margin-right: 2px;
}

.slider-arrow.right {
  margin-left: 2px;
}

.slider-hint {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

.slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background: white;
  transform: translateX(-50%);
  pointer-events: none;
}

.video-loading, .video-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

/* ... 其他样式保持不变 ... */
</style>